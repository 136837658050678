import LoadingButton from '@mui/lab/LoadingButton';
import React, {PropsWithChildren, useState} from 'react';
import PropertiesLoader from "../../properties/PropertiesLoader";
import { Fragment } from 'react';
import { useAuth } from 'react-oauth2-pkce-btoa';
import { GridRenderCellParams } from '@mui/x-data-grid';

const properties = PropertiesLoader();

const DownloadButton : React.FC<PropsWithChildren<{params: GridRenderCellParams<any, any, any>}>> = (props) => {

    const { authService } = useAuth();
    const [isLoadingError, setLoadingError] = useState<boolean>(false);
    const [loadingInvoice, setLoadingInvoice] = useState<boolean>(false);

    async function fetchInvoice(invoiceId: string) {
        setLoadingInvoice(true);
        await fetch(properties.platformApiEndpoint + 'account/v1/invoices/' + invoiceId, {
            method: "GET",
            headers: new Headers({
                Authorization: "Bearer " + authService.getAuthTokens().access_token,
            }),
        })
        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson ? await response.json() : null;

            // check for error response
            if (!response.ok) {
                // get error message from body
                const error = data;
                setLoadingError(true);
                setLoadingInvoice(false);
                return Promise.reject(error);
            }

            setLoadingInvoice(false);

            if(window) {
                window.open(data, '_blank', 'noreferrer')?.focus();
            }
        })
    }

    const onClick = (e: React.MouseEvent<HTMLElement>) => {
        const currentRow = props.params.row;
        fetchInvoice(currentRow.id);
    };

    const buttonText = isLoadingError ? 'Error' : 'Download';

    return (
        <Fragment>
            <LoadingButton 
            variant="outlined" 
            color="info"
            disabled={isLoadingError} 
            size="small" 
            loading={loadingInvoice} 
            onClick={onClick}>{buttonText}</LoadingButton>
        </Fragment>
    )
};

export default DownloadButton;