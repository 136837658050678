import { useContext } from "react";
  
  //Icons
  import ReceiptIcon from '@mui/icons-material/Receipt';
  
  //MUI Component imports
  import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
  import MuiDrawer from "@mui/material/Drawer";
  import List from "@mui/material/List";
  import Divider from "@mui/material/Divider";
  
  //RAM Component imports
  import DrawListItem from "../../components/appdrawer/DrawListItem";
  
  //State
  import { AppStateProvider } from "../../providers/AppStateProvider";
  
  interface AppDrawerProps { }
  
  const drawerWidth = 240;
  
  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });
  
  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });
  
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  
  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));
  
  function AppDrawer(props: AppDrawerProps) {
    const { dashboardContentPage, setContext } = useContext(AppStateProvider);
  
    function setContentPage(page: Number) { 
        setContext({ dashboardContentPage: page });
    }
  
    return (
      <Drawer
        variant="permanent"
        open={true}
        sx={{ display: { sm: "none", md: "block" } }}
      >
        <DrawerHeader></DrawerHeader>
        <Divider />
        <List>
          <DrawListItem
            itemName="Invoices"
            text="Invoices"
            icon={<ReceiptIcon />}
            onClick={() => {
              setContentPage(0);
            }}
            drawOpen={true}
          />
        </List>
      </Drawer>
    );
  }
  
  export default AppDrawer;
  