import React, { createContext, useState } from 'react';
import { Account } from '../service/AccountService';
import { SsoUser } from '../service/ProfileService';

interface UserAccountContextProps {
    user?: SsoUser;
    setUser: React.Dispatch<React.SetStateAction<SsoUser | undefined>>;
    account?: Account
    setAccount: React.Dispatch<React.SetStateAction<Account | undefined>>;
}

export const UserAccountContext = createContext<UserAccountContextProps>({
    user: undefined,
    setUser: () => { },
    account: undefined,
    setAccount: () => { },
});

interface Props {
    children: React.ReactNode;
}

export const UserAccountProvider = ({ children }: Props) => {
    const [user, setUser] = useState<SsoUser | undefined>(undefined);
    const [account, setAccount] = useState<Account | undefined>(undefined);

    return (
        <UserAccountContext.Provider value={{ user, setUser, account, setAccount }}>
            {children}
        </UserAccountContext.Provider>
    );
};