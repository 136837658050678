//Allows use of Auth Service in this component
import { useAuth } from "react-oauth2-pkce-btoa";

//MUI style and component imports
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import {Box} from "@mui/material";


//Import properties loader & Load Props
import PropertiesLoader from "../../properties/PropertiesLoader";
import AccountMenu from "../accountmenu/AccountMenu";
import ProductSelector from "../productselector/ProductSelector";

const properties = PropertiesLoader();

interface AppBarProps {}

function NavBar(props: AppBarProps) {
  //Create functions for login and logout. These should probs live elsewhere
  const { authService } = useAuth();
  const login = async () => authService.authorize();
  const logout = async () => {
    authService.logout();
    window.location.replace(
      properties.authProviderLogoutEndpoint +
        "?redirect_uri=" +
        properties.authRedirect
    );
  };

  //Decide which button to show based on if user is logged in
  let authButton = !authService.isAuthenticated() ? (
    <Button
      variant="contained"
      sx={{
          backgroundColor: "#2c294e",
          color: "white",
          display: "block",
          margin: "10px",
      }}
      onClick={() => {
          login();
      }}
  >Login</Button>
  ) : (null);

  let productSelector = authService.isAuthenticated() ? (
      <ProductSelector/>
  ) : (null);

  let accountDropdown = authService.isAuthenticated() ? (
      <AccountMenu/>
  ) : (null);

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <img
            src="/images/logos/ram-tracking.png"
            style={{
              height: "4.5vh",
              minHeight: "60px",
              pointerEvents: "none",
              padding: "0.5vh",
            }}
            alt="logo"
          />
        </Box>
        {productSelector}
        {accountDropdown}
        {authButton}
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
