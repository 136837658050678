import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {Logout, Person, Support} from "@mui/icons-material";

import "./AccountMenu.css";

//Auth library - Needed due to requirement for PKCE Flow
import { useAuth } from "react-oauth2-pkce-btoa";

import { useContext } from 'react';
import { deepOrange } from '@mui/material/colors';
import { UserAccountContext } from '../../providers/UserAccountProvider';

//Import properties loader & Load Props
import PropertiesLoader from "../../properties/PropertiesLoader";
import { Typography } from '@mui/material';
const properties = PropertiesLoader();

export default function AccountMenu() {

    const userAccountContext = useContext(UserAccountContext)
    const { authService } = useAuth();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Box sx={{className: 'accountDropdown', display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar sx={{ bgcolor: deepOrange[500], width: 32, height: 32 }}>
                            {userAccountContext.user?.firstName.charAt(0).toUpperCase() || <Person />}
                        </Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem>
                    <Avatar>
                        {userAccountContext.user?.firstName.charAt(0).toUpperCase() || <Person />}
                    </Avatar> 
                    {userAccountContext.user?.firstName}
                </MenuItem>
                <MenuItem className='account_callsupport_link'>
                    <ListItemIcon>
                        <Support fontSize="small" />
                    </ListItemIcon>
                    <Typography>
                        Get Help
                    </Typography>
                </MenuItem>
                <MenuItem
                    onClick={async () => {
                        authService.logout();
                        window.Intercom("shutdown");
                        window.location.replace(
                            properties.authProviderLogoutEndpoint
                        );
                    }}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}