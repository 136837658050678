//Auth library - Needed due to requirement for PKCE Flow
import { AuthProvider, AuthService, useAuth } from "react-oauth2-pkce-btoa";
import { ThemeProvider } from "@mui/material/styles";

import { appTheme } from "./themes/AppTheme";
import PropertiesLoader from "./properties/PropertiesLoader";
import HomePage from './components/HomePage/HomePage';

import './App.css';
import NavBar from "./components/NavBar/NavBar";
import { AppState, AppStateProvider, getInitalAppState } from "./providers/AppStateProvider";
import { useCallback, useState } from "react";
import { CssBaseline, styled } from "@mui/material";
import { UserAccountProvider } from "./providers/UserAccountProvider";

import TagManager from 'react-gtm-module'

const properties = PropertiesLoader();
const authService = new AuthService({
  clientId: properties.authClientId,
  location: window.location,
  provider: properties.authProviderEndpoint,
  redirectUri: properties.authRedirect,
  scopes: ['all'],
});


const tagManagerArgs = {
    gtmId: properties.googleTagManagerId
}

TagManager.initialize(tagManagerArgs)

//Offset for the floating nav to make sure content doesnt disapear behind it
const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

function Content() {

  const { authService } = useAuth();

  if(authService.isAuthenticated()){
    return <HomePage />
  } else if (authService.isPending()) {
    return <></>
  } else {
    authService.authorize();
    return <></>
  }
}

function App() {
  const [appState, setAppState] = useState(getInitalAppState());

  // here we only re-create setContext when its dependencies change ([state, setState])
  const setContext = useCallback(
    (updates: AppState) => {
      setAppState({ ...appState, ...updates });
    },
    [appState, setAppState]
  );

  // here context value is just returning an object, but only re-creating the object when its dependencies change ([state, setContext])
  const getContextValue = useCallback(
    () => ({
      ...appState,
      setContext,
    }),
    [appState, setContext]
  );

  return (
    <>
      <AuthProvider authService={authService}>
        <ThemeProvider theme={appTheme}>
          <UserAccountProvider>
            <AppStateProvider.Provider value={getContextValue()}>
              <CssBaseline />
              <NavBar/>
              <Offset />
              <Content />
            </AppStateProvider.Provider>
          </UserAccountProvider>
        </ThemeProvider>
      </AuthProvider>
    </>
  );
}

export default App;
