//Import properties loader & Load Props
import PropertiesLoader from "../properties/PropertiesLoader";
const properties = PropertiesLoader();

export class Role {
  id: number;
  name: string;

  constructor(props: {
    id: number;
    name: string;
  }) {
    this.id = props.id;
    this.name = props.name;
  }
}

export class SsoUser {
  id: string
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  roleList: Role[];
  salesforceId: string
  salesforceContactId: string
  intercomHash : string
  constructor(props: {
    id: string
    username: string;
    email: string,
    firstName: string;
    lastName: string;
    roleList: Role[];
    salesforceId: string
    salesforceContactId: string
    intercomHash : string
  }) {
    this.id = props.id;
    this.username = props.username;
    this.email = props.email;
    this.firstName = props.firstName;
    this.lastName = props.lastName;
    this.roleList = props.roleList;
    this.salesforceId = props.salesforceId;
    this.intercomHash = props.intercomHash
    this.salesforceContactId = props.salesforceContactId
  }
}

export async function getCurrentUserProfile(accessToken: string) {
  return new Promise((resolve, reject) => {
    fetch(properties.platformApiEndpoint + 'account/v1/users/me', {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + accessToken,
      }),
    })
      .then((response) => {
        if (response.status !== 200) {
          reject(response.status);
        }
        return response;
      })
      .then((response) => response.json().then((data) => data as SsoUser))
      .then((ssoUser) => resolve(ssoUser))
      .catch((error) => reject(error));
  });
}
