import { Box } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useAuth } from "react-oauth2-pkce-btoa";
import { AppStateProvider } from "../../providers/AppStateProvider";
import { UserAccountContext } from "../../providers/UserAccountProvider";
import { getCurrentUserProfile, SsoUser } from "../../service/ProfileService";
import AppDrawer from "../appdrawer/AppDrawer";
import InvoicePage from "../InvoicePage/InvoicePage";
import { Account, getCurrentUsersAccount } from "../../service/AccountService";

const HomePage: React.FC<{}> = () => {

    const userAccountContext = useContext(UserAccountContext);
    const { dashboardContentPage, setContext } = useContext(AppStateProvider);
    const { authService } = useAuth();

    function renderContentPage(page: Number) {
      initialiseIntercom();
        switch (page) {
          case 0:
            return < InvoicePage/>;
        }
      }

      React.useEffect(() => {
        //Fetch user details and set context
        async function loadUserData() {
          try {
            let user = await getCurrentUserProfile(authService.getAuthTokens().access_token);
            if (!user) {
              authService.logout();
            }
            let account = await getCurrentUsersAccount(authService.getAuthTokens().access_token);
            if (!account) {
              authService.logout();
            }
            userAccountContext.setUser(user as SsoUser);
            userAccountContext.setAccount(account as Account);
          } catch (error) {
            console.error(
              "Failed to fetch current user profile. Logging out. Error: " + error
            );
            authService.logout()
          }
        }
        //Load on page load
        loadUserData();

      }, []);

      const initialiseIntercom = async() => {
        if(userAccountContext.user && userAccountContext.account){
          window.Intercom("boot", {
            // Account Config
            app_id: "xq6a05yj",
            name: userAccountContext.user?.firstName + " " + userAccountContext.user?.lastName,
            email: userAccountContext.user?.email,
            user_id : userAccountContext.user?.salesforceContactId || userAccountContext.user?.id,
            user_hash: userAccountContext.user?.intercomHash,
            company: {
              company_id: userAccountContext.account?.salesforceId,
              name: userAccountContext.account?.name,
              plan: userAccountContext.account?.featurePackage,
            },
            custom_launcher_selector: '.account_callsupport_link',
            hide_default_launcher: true
          });
        }
        
      };

    return (
        <Box sx={{ display: "flex" }}>
          <AppDrawer />
          {renderContentPage(dashboardContentPage)}
        </Box>
      );
}

export default HomePage;