import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { PropsWithChildren } from "react";

interface ButtonProps {
    src : string, 
    alt : string,
    text: string,
    url: string
}

const ProductSelectorButton: React.FC<PropsWithChildren<ButtonProps>> = (props) => {

    const handleButtonClick = () => {
        if(window) {
            window.open(props.url, '_blank')?.focus();
        }

    }
    return (
        <ListItemButton onClick={handleButtonClick}>
            <ListItemIcon>
                <img
                    src={props.src}
                    style={{
                        height: "4.5vh",
                        minHeight: "60px",
                        pointerEvents: "none",
                        padding: "1vh",
                    }}
                    alt ={props.alt}
                />
            </ListItemIcon>
            <ListItemText
                primary={props.text}/>
        </ListItemButton>
    );
};

export default ProductSelectorButton;