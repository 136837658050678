import { MouseEventHandler, ReactComponentElement } from "react";

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from "@mui/material";

interface DrawListItemProps {
  itemName: string;
  icon: ReactComponentElement<any>;
  text: string;
  onClick: MouseEventHandler<HTMLLIElement>;
  drawOpen: boolean;
}
function DrawListItem(props: DrawListItemProps) {
  return (
    <ListItem
      key={props.itemName}
      onClick={props.onClick}
      disablePadding
      sx={{ display: "block" }}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: props.drawOpen ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: props.drawOpen ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          {props.icon}
        </ListItemIcon>
        <ListItemText
          primary={props.text}
          sx={{ opacity: props.drawOpen ? 1 : 0 }}
        />
      </ListItemButton>
    </ListItem>
  );
}

export default DrawListItem;
