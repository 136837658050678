class EnviromentApplicationProperties {
    authProviderEndpoint: string;
    authProviderLogoutEndpoint: string;
    authClientId: string;
    authRedirect: string;
    platformApiEndpoint: string;
    googleTagManagerId: string;
    constructor(props: {
      authProviderEndpoint: string;
      authProviderLogoutEndpoint: string;
      authClientId: string;
      authRedirect: string;
      platformApiEndpoint: string;
      googleTagManagerId: string;
    }) {
      this.authProviderEndpoint = props.authProviderEndpoint;
      this.authProviderLogoutEndpoint = props.authProviderLogoutEndpoint;
      this.authClientId = props.authClientId;
      this.authRedirect = props.authRedirect;
      this.platformApiEndpoint = props.platformApiEndpoint;
      this.googleTagManagerId = props.googleTagManagerId;
    }
  }
  
  class Configurations {
    local: EnviromentApplicationProperties;
    staging: EnviromentApplicationProperties;
    uat: EnviromentApplicationProperties;
    prod: EnviromentApplicationProperties;
  
    constructor(props: {
      local: EnviromentApplicationProperties;
      staging: EnviromentApplicationProperties;
      uat: EnviromentApplicationProperties;
      prod: EnviromentApplicationProperties;
    }) {
      this.local = props.local;
      this.staging = props.staging;
      this.uat = props.uat;
      this.prod = props.prod;
    }
  }
  
  class ApplicationProperties {
    profile: string;
    configurations: Configurations;
  
    constructor(props: { profile: string; configurations: Configurations }) {
      this.profile = props.profile;
      this.configurations = props.configurations;
    }
  }
  
  export default function () {
    const props = require("./properties.json") as ApplicationProperties;
    switch (props.profile) {
      case "staging":
        return props.configurations.staging;
      case "uat":
        return props.configurations.uat;
      case "prod":
        return props.configurations.prod;
  
      default:
        return props.configurations.local;
    }
  }
  