import React from "react";

export function getInitalAppState(): AppState {
  return initalAppState;
}

const initalAppState: AppState = {
  dashboardContentPage: 0,
};

export type AppState = {
  dashboardContentPage: Number;
};

export const AppStateProvider = React.createContext({
  setContext: (updates: AppState) => { },
  dashboardContentPage: initalAppState.dashboardContentPage,
});
