import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {ListItem, Typography} from "@mui/material";
import {AppsRounded} from "@mui/icons-material";
import ProductSelectorButton from './ProductSelectorButton';

export default function ProductSelector() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Box sx={{className: 'accountDropdown', display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <AppsRounded fontSize="large"/>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="product-selector"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        width: 400,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <ListItem>
                    <Typography variant="h6">
                        Access our products
                    </Typography>
                </ListItem>
                <Divider />
                <ProductSelectorButton alt="RAM Tracking Logo" 
                    src="/images/logos/ram-tracking.png"
                    text="Monitor vehicles in the field and improve fleet operations."
                    url="https://www.ramwebtracking.co.uk/" />
                <Divider />
                <ProductSelectorButton alt="RAM Assist Logo" 
                    src="/images/logos/ram-assist-logo.png"
                    text="Complete driver activity and vehicle management solution."
                    url="https://ramassist.com/portal" />
                <Divider />
                <ProductSelectorButton alt="RAM Live Logo" 
                    src="/images/logos/ram-live-logo.png" 
                    text="Connected dashcam solution with live streaming and journey video footage."
                    url="https://live.ramtracking.com/" />
                <Divider />
                <ProductSelectorButton alt="RAM Marketplace Logo"
                    src="/images/logos/ram-marketplace-logo.png" 
                    text="Exclusive discount and offers from some of the biggest companies in the UK."
                    url="https://www.ramtracking.com/marketplace/" />
                <Divider />
            </Menu>
        </React.Fragment>
    );
}