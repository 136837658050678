import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Invoice from '../../models/Invoice';
import DownloadButton from './DownloadButton';
import PropertiesLoader from "../../properties/PropertiesLoader";
import { Fragment, useEffect, useState } from 'react';
import { useAuth } from 'react-oauth2-pkce-btoa';
import './InvoicesTable.css';

const properties = PropertiesLoader();

const InvoiceTable : React.FC<{}> = () => {
    const { authService } = useAuth();
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [loadingAllInvoices, setLoadingAllInvoices] = useState<boolean>(false);

    async function fetchInformation() {
        setLoadingAllInvoices(true);
        await fetch(properties.platformApiEndpoint + 'account/v1/invoices/', {
            method: "GET",
            headers: new Headers({
                Authorization: "Bearer " + authService.getAuthTokens().access_token,
            }),
        })
        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson ? await response.json() : null;

            // check for error response
            if (!response.ok) {
                // get error message from body
                const error = data;
                setLoadingAllInvoices(false);
                return Promise.reject(error);
            }

            setLoadingAllInvoices(false);

            const invoicesDate = data.map((invoice : Invoice) => {
                return {
                    id: invoice.id,
                    documentDate: invoice.documentDate,
                    documentType: invoice.documentType,
                    agreementNumber: invoice.agreementNumber,
                    externalNumber: invoice.externalNumber,
                    outstandingValue: invoice.outstandingValue,
                    paymentStatus: invoice.paymentStatus,
                    dueDate: invoice.dueDate,
                    totalAmount: invoice.totalAmount
                };
            });

            setInvoices(invoicesDate);
        })
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Document Number', width: 100, disableColumnMenu: true, flex: 1 },
        { field: 'documentDate', headerName: 'Invoice Date', type: 'date', width: 160, disableColumnMenu: true, flex: 1 },
        { field: 'externalNumber', headerName: 'External Number', width: 130, disableColumnMenu: true, flex: 1  },
        { field: 'agreementNumber', headerName: 'Agreement Number', width: 160, disableColumnMenu: true, flex: 1  },
        { field: 'documentType', headerName: 'Document Type', width: 160, disableColumnMenu: true, flex: 1  },
        { field: 'totalAmount', headerName: 'Total Amount', width: 130, disableColumnMenu: true, flex: 1  },
        { field: 'dueDate', headerName: 'Due Date', type: 'date', width: 160, disableColumnMenu: true, flex: 1  },
        { field: 'paymentStatus', headerName: 'Status', width: 160, disableColumnMenu: true, flex: 1  },
        { field: 'actions', 
            headerName: 'Actions', 
            width: 160,
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <DownloadButton params={params}></DownloadButton>
                );
            },
     }
    ];
  
  const rows: Invoice[] | undefined = [ ...invoices];

  useEffect(() => {
    fetchInformation();
  }, []);
  
  return  <Fragment>
        <div style={{ height: 500, 
            width: '95%', 
            margin: 'auto'}}>
            <DataGrid
            initialState={{
                sorting: {
                    sortModel: [{ field: 'documentDate', sort: 'desc' }],
                },
            }}
            components={{
                NoRowsOverlay: CustomNoRowsOverlay
            }}
            rows={rows}
            loading={loadingAllInvoices}
            columns={columns}
            pageSize={12}
            rowsPerPageOptions={[12, 24, 36, 100]}
            checkboxSelection={false}
            disableSelectionOnClick
            />
        </div>
    </Fragment>
}

const CustomNoRowsOverlay: React.FC<{}> = () => {
    return <h1>No invoices at this time.</h1>;
}

export default InvoiceTable;