//Import properties loader & Load Props
import PropertiesLoader from "../properties/PropertiesLoader";
const properties = PropertiesLoader();

export async function getCurrentUsersAccount(accessToken: string) {
    return new Promise((resolve, reject) => {
        fetch(properties.platformApiEndpoint + 'account/v1/accounts/me', {
            method: "get",
            headers: new Headers({
                Authorization: "Bearer " + accessToken,
            }),
        })
            .then((response) => {
                if (response.status !== 200) {
                    reject(response.status);
                }
                return response;
            })
            .then((response) => response.json().then((data) => data as Account))
            .then((account) => resolve(account))
            .catch((error) => reject(error));
    });
};

export class Account {
    id: number;
    name: string;
    salesforceId: string;
    featurePackage: string;
    constructor(props: {
        id: number;
        name: string;
        salesforceId: string;
        featurePackage: string;
    }) {
        this.id = props.id;
        this.name = props.name;
        this.salesforceId = props.salesforceId;
        this.featurePackage = props.featurePackage;
    }
}
