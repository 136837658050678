import { useContext } from "react";
import { UserAccountContext } from "../../providers/UserAccountProvider";
import InsufficientAccess from "./InsufficientAccess";
import InvoiceTable from "./InvoiceTable";

import './InvoicePage.css'
import Instructions from "./Instructions";

const InvoicePage : React.FC = () => {
    const userAccountContext = useContext(UserAccountContext);

    const invoicePageContent = () => {
        let isAdmin = userAccountContext.user?.roleList.some(role => role.name === 'Customer Super Admin' || role.name === 'Customer Admin');

        return isAdmin ? <InvoiceTable/> : <InsufficientAccess/>
    };

    return <div style={{display: 'grid', width: '100vw'}}>
        <h3>Invoices</h3>    
        <Instructions/>

        {invoicePageContent()}
    </div>
};

export default InvoicePage;