import './Instructions.css'

const Instructions : React.FC = () => {
    return (
        <p>
            Below you will see a list of invoices for your RAM Account. 
            If an invoice PDF is stored in our system you will be able to download a copy of it using the Download button however some older invoices my not be available and you will need to request them by <button className="account_callsupport_link" onClick={() => {return true;}}>clicking here</button> and selecting 'Send us a Message' and then 'Talk to Accounts'.
        </p>
    );
}
export default Instructions;