import { blue, pink } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
  palette: {
    primary: { main: "#f5f5f5" },
    secondary: { main: "#e0e0e0" },
  },
  typography: {
    listsectiontitle: {
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 5,
      fontSize: 15,
      fontWeight: 'normal',
    },

    cardtitle: {
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: 15,
      fontWeight: 'bold'
    },
    cardsubtitle: {
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: 12,
      fontWeight: 'bold'
    },
    cardbody: {
      paddingTop: 2,
      paddingBottom: 2,
      fontSize: 12,
      fontWeight: 'normal'
    }
  },
});


declare module '@mui/material/styles' {
  interface TypographyVariants {
    cardtitle: React.CSSProperties;
    cardsubtitle: React.CSSProperties;
    cardbody: React.CSSProperties;
    listsectiontitle: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    cardtitle?: React.CSSProperties;
    cardsubtitle?: React.CSSProperties;
    cardbody?: React.CSSProperties;
    listsectiontitle?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    cardtitle: true;
    cardsubtitle: true;
    cardbody: true;
    listsectiontitle: true;
  }
}

declare module "@mui/material/styles/createPalette" {
  interface Palette {    
    contrast: Palette["primary"];
  }
  

  interface PaletteOptions {    
    contrast?: PaletteOptions["primary"];
  } 

}